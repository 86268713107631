export const formatCountry = (country) => {
    let formattedCountry
    if (country === 'United Kingdom of Great Britain and Northern Ireland') {
      formattedCountry = 'UK'
    }
    else if (country === 'United Arab Emriates') {
      formattedCountry = 'UAE'
    } else if (country === 'Russian Federation') {
      formattedCountry = 'Russia'
    } else if (country === 'United States of America') {
      formattedCountry = 'USA'
    }
    else if (country === 'Venezuela (Bolivarian Republic of)'){
      formattedCountry = 'Venezuela'
    }
    else {
      formattedCountry = country
    }
    return formattedCountry
}