import React, { useState } from "react"
import "./App.scss"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Add from "./components/Add"
import Edit from "./components/Edit"
import Details from "./components/Details"
import About from "./components/About"
import Footer from "./components/Footer"
import Home from "./components/Home"

const App = () => {
  const [mode, setMode] = useState("day")
  const changeMode = () => {
    const value = mode === "day" ? "night" : "day"
    setMode(value)
  }
  return (
    <BrowserRouter>
      {/* <Header /> */}
      <main className={mode === "night" ? "night" : ""}>
        <Routes>
          <Route
            index
            element={
              <Home
                mode={mode}
                changeMode={changeMode}
              />
            }
          />
          <Route
            path='/testimony/add'
            element={<Add />}
          />
          <Route
            path='testimony/edit/:id'
            element={<Edit />}
          />
          <Route
            path='testimony/:id'
            element={<Details />}
          />
          <Route
            path='about'
            element={About}
          />

          {/* <Route exact path = '/' component = {Items} /> */}
        </Routes>
      </main>

      <Footer />
    </BrowserRouter>
  )
}

export default App
