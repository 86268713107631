import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "./assets/styles/about.scss"
import "./assets/styles/common.scss"
import "./assets/styles/form.scss"
import "./assets/styles/buttons.scss"
import "./assets/styles/icons.scss"
import "./assets/styles/card.scss"
import "./assets/styles/footer.scss"
import "./assets/styles/responsive.scss"
import './assets/styles/detail.scss'
import './assets/styles/graph.scss'

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
