const Footer = (props) => (
  <footer>
    <div>
      <small className='copyright'>
        Copyright &copy; 2021 | Asabeneh Yetayeh
      </small>
    </div>
    <div>
      <a
        href='https://github.com/Asabeneh'
        rel='noopener noreferrer'
        target='_blank'
      >
        <i className='fab fa-github-square'></i>
      </a>
      <a
        href='https://www.linkedin.com/in/asabeneh/'
        rel='noopener noreferrer'
        target='_blank'
      >
        <i className='fab fa-linkedin'></i>
      </a>
      <a
        href='https://twitter.com/Asabeneh'
        rel='noopener noreferrer'
        target='_blank'
      >
        <i className='fab fa-twitter-square'></i>
      </a>
    </div>
    <div className='arrow-icon-wrapper'>
      <a href='#top'>
        {/* <i className="fas fa-arrow-alt-circle-up"></i> */}
        <i className='fas fa-chevron-circle-up'></i>
      </a>
    </div>
  </footer>
)

export default Footer
