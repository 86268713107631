import React from 'react'
import { Link } from 'react-router-dom'
import Rate from './Rate'
import SocialLinks from './SocialLinks'
import { formatCountry } from '../utils/format-country'

const Item = ({
  flag,
  item: {
    firstName,
    lastName,
    country,
    company,
    gender,
    profession,
    rating,
    program,
    testimony,
    linkedIn,
    gitHub,
    _id,
    mentor,
    views,
    imageUrl,
  },
}) => {
  let formattedCountry = formatCountry(country)
  return (
    <div className='card'>
      <div className='card-header'>
        <img
          src={imageUrl}
          alt={firstName}
          loading='lazy'
          className='card-image'
        />
        {mentor && <i className='fas fa-certificate' title='MENTOR'></i>}
        <div className='profile-social-links'>
          <SocialLinks linkedIn={linkedIn} gitHub={gitHub} mentor={mentor} />
        </div>
      </div>
      <div className='card-body desc'>
        <div>
          <h2>
            {gender === 'Male' ? (
              <i className='fas fa-male gender-icon'></i>
            ) : (
              <i className='fas fa-female gender-icon'></i>
            )}{' '}
            {}
            <Link to={`/testimony/${_id}`} className='name'>
              {firstName.toUpperCase()} {lastName.toUpperCase()}
            </Link>
          </h2>

          <h3>{profession}</h3>

          <div className='flag-wrapper'>
            <img
              src={flag}
              alt={formattedCountry}
              loading='lazy'
              className='flag-image'
            />
            {formattedCountry && <small>{formattedCountry}</small>}
            {company && (
              <>
                <small>,</small>
                <small className='company'> &nbsp;{company}</small>
              </>
            )}
          </div>

          <h1
            style={{ margin: '15px auto', fontSize: '22px', fontWeight: 500 }}
          >
            {program}
          </h1>

          <blockquote>
            <i className='fas fa-quote-left'></i>
            <p style={{ fontSize: 13 }}>
              {testimony.slice(0, 100)}
              <br />
              <Link to={`/testimony/${_id}`} className='name'>
                Read More
              </Link>
            </p>

            <i className='fas fa-quote-right'></i>
          </blockquote>
        </div>
      </div>
      {/* <div className='card-footer'>
                  <div><i class="fas fa-heart"></i> 33</div>
                  <div><i class="fas fa-comment"></i> 10</div>
                  <div><i class="fas fa-eye"></i>150</div>
              </div> */}
      <Rate rating={rating} />
      {views.length > 0 && (
        <>
          <div className='views'>
            <i className='fas fa-eye'></i>
            <span>{views.length}</span>
          </div>
        </>
      )}
    </div>
  )
}

export default Item
