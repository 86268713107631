import React from 'react'

const SocialLinks = ({ linkedIn, gitHub }) => (
    <>
      {linkedIn && (
        <a
          href={linkedIn}
          target='_blank'
          rel='noreferrer'>
          <i className='fab fa-linkedin'></i>
        </a>
      )}
      {gitHub && (
        <a
          href={gitHub}
          target='_blank'
          rel='noreferrer'>
          <i className='fab fa-github-square'></i>
        </a>
      )}
    </>
  )

export default SocialLinks