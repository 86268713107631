import React from 'react'
import asabenehImage from '../assets/images/asabeneh.jpg'
const About = (props) => (
  <section className='about'>
    <h1 className='section-title about-title'>Asabeneh Yetayeh</h1>
    <div className='row'>
      <div className='image-wrapper'>
        <img src={asabenehImage} alt='asabeneh' />
        <div className='about-social-icons-wrapper'>
          <a
            href='https://www.linkedin.com/in/asabeneh/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='fab fa-linkedin'></i>
          </a>
          <a
            href='https://github.com/Asabeneh'
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='fab fa-github-square'></i>
          </a>
          <a
            href='https://twitter.com/Asabeneh'
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='fab fa-twitter-square'></i>
          </a>
          <a
            href='https://www.youtube.com/channel/UC7PNRuno1rzYPb1xLa4yktw'
            target='_blank'
            rel='noopener noreferrer'
          >
            <i className='fab fa-youtube-square'></i>
          </a>
        </div>
      </div>
      <div className='text-wrapper'>
        <p>
          I am an instructor, developer, content creator and motivator. I am
          married and I live in Finland. I attended different universities and
          that gave me an insight for many of the activities I do right now. I
          teach different technologies specially HTML, CSS, Sass, JS, React,
          Redux, Node, MongoDB, Python,Data Analysis with Python, and D3.js. In
          addition, I am a data science and machine learning enthusiast. I enjoy
          creating different educational materials. So far, I have created{' '}
          <a
            className='link'
            href='https://github.com/Asabeneh/30-Days-Of-Python'
            rel='noopener noreferrer'
            target='_blank'
          >
            30 Days Of Python
          </a>
          ,{' '}
          <a
            className='link'
            href='https://github.com/Asabeneh/30-Days-Of-JavaScript'
            rel='noopener noreferrer'
            target='_blank'
          >
            30Days Of JavaScript
          </a>
          ,{' '}
          <a
            className='link'
            href='https://github.com/Asabeneh/30-Days-Of-React'
            rel='noopener noreferrer'
            target='_blank'
          >
            30 Days Of React
          </a>
          , 10 Days of Git and GitHub, 10 Days Of Python, Numpy, Pandas,
          Matplotlib and 30DaysOfHTML. In the coming years, I will create more
          programming challenges. After the Corona Pandemic, I will travel
          around the world, teach programming and meet 30DaysOfJavaScript,
          30DaysOfPython and 30DaysOfReact fans.
        </p>
      </div>
    </div>
  </section>
)

export default About
