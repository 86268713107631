import React from 'react'
import '../../assets/styles/course.scss'
import cardPhoto from '../../assets/images/card_photo.gif'
import pythonLogo from '../../assets/images/python_logo.jpg'
import reactLogo from '../../assets/images/react_logo.png'
import jsLogo from '../../assets/images/js_logo.png'

const GitRepo = ({ course }) => {
  let status = course.status.toLowerCase()
  return (
    <div className={'course-card ' + status}>
      <div className={'course-card-item course-first-card-item '}>
        {course.githubUrl.length > 0 ? (
          <a
            href={course.githubUrl}
            aria-label={course.name}
            target='_blank'
            rel='noopener noreferrer'
          >
            {' '}
            <img src={course.image} alt={course.name} />
          </a>
        ) : (
          <img src={course.image} alt={course.name} />
        )}
      </div>
      <div className='course-card-item'>
        <div className='course-card-header'>
          <img src={course.stars} className='git-stars' alt={'stars'} />
          <div>
            <h1 className='course-card-title'>{course.name}</h1>
            <h2 className='course-card-subtitle'>{course.topics[0]}</h2>
          </div>
        </div>
        <div className='course-card-body'>{course.description}</div>
      </div>
    </div>
  )
}

GitRepo.propTypes = {}

export default GitRepo
