import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Item from './Item'
import useFetch from './useFetch'
import { formatCountry } from '../utils/format-country'
import { sortArrayOfObject } from '../utils/sort-array'

import ReactWordcloud from 'react-wordcloud'

const words = [
  {
    text: 'told',
    value: 64,
  },
  {
    text: 'mistake',
    value: 11,
  },
  {
    text: 'thought',
    value: 16,
  },
  {
    text: 'bad',
    value: 17,
  },
]

function SimpleWordcloud() {
  return <ReactWordcloud words={words} />
}

const Graph = ({ country, number, flag }) => (
  <div className='bars'>
    <div className='flag-wrapper'>
      <img src={flag} alt={country} loading='lazy' className='flag-image' />
      {formatCountry(country)}
    </div>
    <div className='bar' style={{ width: `${number}%`, height: `35px` }}></div>
    <div>{number}</div>
  </div>
)

const Items = (props) => {
  const url = 'https://restcountries.com/v2/all'
  const countries = useFetch(url)
  const [items, setItems] = useState([])
  useEffect(() => {
    axios
      .get('/api/testimonials')
      .then((response) => {
        setItems(response.data)
      })
      .catch((err) => {
        console.log('Error form')
      })
  }, [])

  let data = []
  const distinctCountries = new Set(items.map((c) => c.country))
  for (const c of distinctCountries) {
    const num = items.filter((itm) => itm.country === c).length
    const flag = countries.find((country) => country.name === c)?.flag
    data.push({ country: c, number: num, flag: flag })
  }
  data = sortArrayOfObject(data, 'number')

  let list
  if (!items && countries.length === 0) {
    list = <p>There is no testimonies</p>
  } else {
    list = items.map((item, i) => (
      <Item
        item={item}
        key={item._id}
        flag={
          countries.find((c) => c.name === item.country) &&
          countries.find((c) => c.name === item.country).flag
        }
      />
    ))
  }

  return items.length > 0 ? (
    <>
      <div>
        <h2 style={{ textTransform: 'uppercase', textAlign: 'center' }}>
          Testimonials ({items.length})
        </h2>
      </div>
      <div className='header-links'>
        <Link to='/testimony/add' className='btn'>
          <i className='fas fa-plus'></i> Add Testimony
        </Link>
        <a href='#stat'>
          <i className='fas fa-chart-bar'></i>
        </a>
      </div>

      <div className='cards'>{list}</div>
      <div>
        <div className='graph-header'>
        <h4 className='graph-title'>Participants Location</h4>
        <small>Testionalis from {data.length} countries</small>
        </div>
        <div className='graphs'>
          <div className='graph-wrapper' id='stat'>
            {data.map((d) => (
              <Graph
                key={d.country}
                country={d.country}
                number={d.number}
                flag={d.flag}
              />
            ))}
          </div>
        </div>
      </div>
      {/* <SimpleWordcloud /> */}
    </>
  ) : (
    <div className='spinner'>
      <i className='fa fa-solid fa-spinner fa-spin'></i>
    </div>
  )
}

export default Items
