export const trainings = [
  { label: '* Select program', value: '' },
  { label: '9 Months Web Development', value: '9 Months Web Development' },
  { label: '6 Months Web Development', value: '6 Months Web Development' },
  { label: '3 Months Web Development', value: '3 Months Web Development' },
  { label: '6 Months Fullstack', value: '6 Months Fullstack' },
  { label: '3 Months Fullstack', value: '3 Months Fullstack' },
  { label: '3 Months Data Analysis', value: '3 Months Data Analysis' },
  { label: '6 Months Data Analysis', value: '6 Months Data Analysis' },
  { label: '6 Months Mobile App Dev', value: '6 Months Mobile App Dev' },
   { label: 'Machine Learning', value: 'Machine Learning' },
   { label: 'Statistics with Python', value: 'Statistics with Python' },
    { label: 'Mathematics with Python', value: 'Mathematics with Python' },
  { label: 'React Bootcamp', value: 'React Bootcamp' },
  { label: 'JavaScript Bootcamp', value: 'JavaScript Training' },
  { label: 'Python Bootcamp', value: 'Python Bootcamp' },
  {label: '30DaysOfPython challenge',value: '30DaysOfPython challenge'},
  {label: '30DaysOfJavaScript challenge',value: '30DaysOfJavaScript challenge'},
    {label: '30DaysOfReact challenge',value: '30DaysOfReact challenge'},
  { label: 'HTML and CSS', value: 'HTML and CSS' },
  { label: 'Git and GitHub Course', value: 'Git and GitHub Course' },
  { label: 'React Course', value: 'React Course' },
  { label: 'Python Course', value: 'Python Course' },
   { label: 'Data Analysis with Python', value: 'Data Analysis with Python' },
    { label: 'Modern JavaScript Course', value: 'Modern JavaScript Course' },
  { label: 'JavaScript', value: 'JavaScript' },
  { label: 'Other', value: 'Other' },
]