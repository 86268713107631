import React from 'react'
import About from './About'
import Items from './Items'
import GitRepos from './git-repos/GitRepos'

const Home = (props) => (
  <div>
    <About />
    <GitRepos />
    <Items />
  </div>
)

export default Home
